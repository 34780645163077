










import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
export default Vue.extend({
  name: 'DocumentSearch',
  data: () => ({
    icon: 'search'
  }),
  components: {},

  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings
    })
  },
  methods: {
    ...(mapActions({
      setQueryText: 'userProfile/setQueryText'
    }) as any),

    handleUpdateSearch(query: string) {
      if (query) {
        this.icon = '';
      }
      this.setQueryText(query);
    }
  }
});
