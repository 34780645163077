




import Vue from 'vue';
import DocumentsList from '@/components/Document/List/DocumentsList.vue';
export default Vue.extend({
  components: {
    DocumentsList
  }
});
