











import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import DocumentFilter from '@/models/Document/DocumentFilter';
export default Vue.extend({
  name: 'TransactionFilter',
  data: () => ({}),
  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings
    }),
    filters(): DocumentFilter[] {
      return Object.values(DocumentFilter);
    }
  },
  methods: {
    ...(mapActions({
      setSelectedFilter: 'userProfile/setSelectedFilter'
    }) as any),
    handleSelectFilter(e: any): void {
      this.setSelectedFilter(this.userSettings.selectedFilter);
    }
  }
});
