















import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Document from '@/models/Document/Document';
import DocumentTableHeader from '@/components/Document/List/DocumentTableHeader.vue';
import DocumentTableData from '@/components/Document/List/DocumentTableData.vue';
import DocumentFilter from '@/models/Document/DocumentFilter';
import { filterDocuments } from '@/helpers/DocumentFilterHelper';
import stringMixin from '@/mixins/stringMixin';

export default Vue.extend({
  name: 'ProjectDocument',
  props: ['scrollToDocumentId', 'projectName', 'documents'],
  mixins: [stringMixin],
  components: {
    DocumentTableHeader,
    DocumentTableData
  },
  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings
    }),
    shownDocuments(): Document[] {
      return filterDocuments(this.documents);
  },
  projectTitle() {
      return (this.userSettings.selectedFilter == DocumentFilter.NOTSENT)?this.projectName:'';
  }
  },
  methods: {
    handleDocumentClick(documentId: string) {
      this.$emit('handleDocumentClick', documentId);
    }
  }
});
