







import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
  name: 'NewDataSnackbar',
  data: () => ({
    show: false
  }),
  created() {
    this.handleRefresh();
  },
  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings,
      isNewDocumentData: (state: any) => state.documents.isNewData,
      isNewPictureData: (state: any) => state.pictures.isNewData,
      isNewProjectData: (state: any) => state.projects.isNewData,
      isNewProductData: (state: any) => state.products.isNewData,
      isNewMeasurementData: (state: any) => state.measurements.isNewData,
      isNewCoaData: (state: any) => state.coas.isNewProductData,
      isInSyncDocument: (state: any) => state.documents.isInSync,
      isInSyncPicture: (state: any) => state.pictures.isInSync,
      isInSyncProject: (state: any) => state.projects.isInSync,
      isInSyncProduct: (state: any) => state.products.isInSync,
      isInSyncMeasurements: (state: any) => state.measurements.isInSync,
      isInSyncCoas: (state: any) => state.coas.isInSync
    }),

    isInSync() {
      const inSync =
        this.isInSyncDocument ||
        this.isInSyncPicture ||
        this.isInSyncProject ||
        this.isInSyncProduct ||
        this.isInSyncMeasurements ||
        this.isInSyncCoas;

      return inSync;
    },
    isNewData() {
      const isNew =
        this.isNewDocumentData ||
        this.isNewPictureData ||
        this.isNewProjectData ||
        this.isNewProductData ||
        this.isNewMeasurementData ||
        this.isNewCoaData;

      this.handleRefresh();
      return isNew;
    },

    message() {
      if (this.isInSyncDocument) {
        return 'Syncing Documents...';
      } else if (this.isInSyncPicture) {
        return `Syncing Pictures...`;
      } else if (this.isInSyncProject) {
        return `Syncing Projects...`;
      } else if (this.isNewData) {
        return 'New Data Available';
      } else {
        return 'Nothing to do.';
      }
    }
  },
  methods: {
    ...(mapActions({
      getAllDocuments: 'documents/getAll',
      getAllPictures: 'pictures/getAll',
      getAllProducts: 'products/getAll',
      getAllMeasurements: 'measurements/getAll',
      getAllCoas: 'coas/getAll'
    }) as any),

    async handleRefresh() {
      await Promise.all([
        this.getAllDocuments(),
        this.getAllPictures(),
        this.getAllProducts(),
        this.getAllMeasurements(),
        this.getAllCoas()
      ]);
    }
  }
});
