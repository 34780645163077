
































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Date from '@/components/Common/Date.vue';
import moment from 'moment';
import IFilter from '@/models/IFilter';
import FilterNames from '@/models/FilterNames';
export default Vue.extend({
  name: 'DocumentMoreFilters',
  data: () => ({
    dateFromString: '',
    dateToString: ''
  }),
  components: {
    Date
  },
  watch: {
    filters: {
      handler(filters: IFilter[]) {
        const dateFilter = this.filters.find((f: IFilter) => f.name === FilterNames.DATE);
        const dateParams = dateFilter.parameters;
        if (dateParams.dateFrom) {
          const date = moment(dateParams.dateFrom).toDate();
          this.dateFromString = date.toDateString();
        } else {
          this.dateFromString = '';
        }
        if (dateParams.dateTo) {
          const date = moment(dateParams.dateTo).toDate();
          this.dateToString = date.toDateString();
        } else {
          this.dateToString = '';
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      filters: (state: any) => state.userProfile.settings.filters
    }),
    dateParams() {
      const filter = this.filters.find((f: IFilter) => f.name === FilterNames.DATE);
      return filter.parameters;
    },
    amountParams() {
      const filter = this.filters.find((f: IFilter) => f.name === FilterNames.AMOUNT);
      return filter.parameters;
    },

    flagParams() {
      const filter = this.filters.find((f: IFilter) => f.name === FilterNames.FLAGS);
      return filter.parameters;
    }
  },
  methods: {
    ...(mapActions({
      setDateFrom: 'userProfile/setDateFrom',
      setDateTo: 'userProfile/setDateTo',
      setAmountFrom: 'userProfile/setAmountFrom',
      setAmountTo: 'userProfile/setAmountTo',
      setRedFlag: 'userProfile/setRedFlag',
      setVerified: 'userProfile/setVerified',
      setApproved: 'userProfile/setApproved',
      setAsc: 'userProfile/setAsc'
    }) as any),

    handleFromDateChange(dateFrom: string) {
      const newDate = moment(dateFrom)
        .utc()
        .toDate();
      this.setDateFrom(newDate.getTime());
    },

    handleToDateChange(dateTo: string) {
      const newDate = moment(dateTo)
        .utc()
        .toDate();
      this.setDateTo(newDate.getTime());
    },

    handleInputAmountFrom(amount: number) {
      this.setAmountFrom(amount);
    },

    handleInputAmountTo(amount: number) {
      this.setAmountTo(amount);
    },

    handleRedFlagClick() {
      this.setRedFlag(this.flagParams.isRedFlag);
    },
    handleVerifiedFlagClick() {
      this.setVerified(this.flagParams.isVerified);
    },

    handleApprovedFlagClick() {
      this.setApproved(this.flagParams.isApproved);
  },

    handleAscFlagClick() {
      this.setAsc(this.flagParams.isAsc);
    }
  }
});
