import { render, staticRenderFns } from "./DocumentTableData.vue?vue&type=template&id=f454a58a&"
import script from "./DocumentTableData.vue?vue&type=script&lang=ts&"
export * from "./DocumentTableData.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentTableData.vue?vue&type=style&index=0&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCheckbox,VFlex,VHover,VIcon,VLayout})
