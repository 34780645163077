












import Vue from 'vue';
export default Vue.extend({
  name: 'Projects',
  props: ['projects'],
  computed: {
    height(): string {
      return this.$vuetify.breakpoint.height - 200 + 'px';
    }
  }
});
