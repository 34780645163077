
































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import FileInputCamera from '@/components/Common/Camera/FileInputCamera.vue';
import Document from '@/models/Document/Document';
import AddButton from '@/components/Common/AddButton.vue';
import { sendDocuments } from '@/helpers/SendHelper';
import Projects from '@/components/Document/List/Projects.vue';
import ProjectDocuments from '@/components/Document/List/ProjectDocuments.vue';
import SendButton from '@/components/Document/SendButton.vue';
import DocumentToolbar from '@/components/Document/DocumentToolbar.vue';
import DocumentFilter from '@/models/Document/DocumentFilter';
import NewDataSnackBar from '@/components/Common/NewDataSnackbar.vue';
import stringMixin from '@/mixins/stringMixin';

import { isFileInputCamera } from '@translucide/lucid-app-camera/src/helpers/browserHelper';

export default Vue.extend({
  name: 'DocumentsList',
  data: () => ({
    tmpDocumentId: null
  }),
  mixins: [stringMixin],
  components: {
    AddButton,
    SendButton,
    Projects,
    ProjectDocuments,
    DocumentToolbar,
    NewDataSnackBar,
    FileInputCamera
  },
  props: ['scrollToDocumentId'],
  mounted() {
    if (this.scrollToDocumentId) {
      const id = '#' + this.convertToValidCssSelector(this.scrollToDocumentId);
      this.$vuetify.goTo(id, {
        container: '#scroll-target',
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 200
      });
   }
   else{
    this.$vuetify.goTo(0, {
        container: '#scroll-target',
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 0
      });
   }
  },
  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings
    }),    
    ...mapGetters({
      getDocumentsByProjectId: 'documents/byProjectId',
      getProjectsWithDocuments: 'projects/hasDocuments',
      getLatestDocumentByProjectId: 'documents/latestByProjectId',
      getDocumentsToSendCount: 'documents/toSendCount'
    })
  },
  methods: {
    ...(mapActions({
      createDocument: 'documents/create'
    }) as any),

    handleSend() {
      sendDocuments();
    },

    async handleCreate() {
      const newId = await this.createDocument();

      if (isFileInputCamera()) {
        const input = (this.$refs.inputCamera as any).$refs.fileInput as HTMLInputElement;
        input.click();
        this.tmpDocumentId = newId;
      } else {
        this.handleDocumentClick(newId);
      }
    },

    handleDocumentClick(documentId: string, isNew: boolean) {
      this.$router.push({ name: 'Document', params: { documentId } });
    },

    handleTakePicture(imgSrc: string) {
      this.$router.push({ name: 'Document', params: { documentId: this.tmpDocumentId, imgSrc } });
    }
  }
});
