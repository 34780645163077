












































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import DocumentFilter from '@/components/Document/DocumentFilter.vue';
import DocumentSearch from '@/components/Document/DocumentSearch.vue';
import DocumentMoreFilters from '@/components/Document/DocumentMoreFilters.vue';
import FullScreenTooltip from '@/components/Common/FullScreenTooltip/FullScreenTooltip.vue';
import ActivatorLabelStyles from '@/components/Common/FullScreenTooltip/ActivatorLabelStyles';
export default Vue.extend({
  name: 'DocumentToolbar',
  data: () => ({}),
  mounted() {},
  components: {
    DocumentFilter,
    DocumentSearch,
    FullScreenTooltip,
    DocumentMoreFilters
  },
  computed: {
    ...mapGetters({
      getFilterCount: 'userProfile/filterCount'
    }),
    activatorLabelStyle() {
      return this.getFilterCount() > 0 ? ActivatorLabelStyles.bold : ActivatorLabelStyles.italic;
    }
  },
  methods: {
    ...(mapActions({
      applyFilters: 'userProfile/applyFilters',
      cancelFilters: 'userProfile/cancelFilters',
      clearFilters: 'userProfile/clearFilters'
    }) as any),
    handleSelectFilter(selected: any): void {
      this.$emit('handleSelectFilter', selected);
    }
  }
});
